import { useEffect, useState } from 'react'
import { EventTypes, IDataLayerHeaderParams, IDataLayerHeader } from './typesUseDataLayer'

/**
 *  Data Layer Event
 *  Saiba Mais: https://confluence.bancointer.com.br/pages/viewpage.action?pageId=188390035#Novopadr%C3%A3odetagueamento-PARADEVS
 */

const useDataLayerHeader = (event?: EventTypes) => {
  const [ dataLayerEvent, setDataLayerEvent ] = useState<EventTypes>('ga_event_header')

  useEffect(() => {
    event && setDataLayerEvent(event)
  }, [ dataLayerEvent ])

  const returnDataLayer = (data: IDataLayerHeaderParams) => {
    const { section, sub_section, element_action, element_name, c_page, redirect_url, element_search, step }: IDataLayerHeaderParams = data

    const dataLayer: IDataLayerHeader = {
      event: dataLayerEvent,
      params: {
        section: section || 'null',
        sub_section: sub_section || 'null',
        element_action: element_action,
        element_name: element_name,
        c_page: c_page,
        redirect_url: redirect_url || 'null',
        element_search: element_search || 'null',
        step: step || 'null',
      },
    }

    return dataLayer
  }

  const sendDatalayerHeaderEvent = (dataLayer: IDataLayerHeaderParams) => {
    const dataLayerEvent = returnDataLayer(dataLayer)

    if (window && window.dataLayer) {
      window.dataLayer.push(dataLayerEvent)
    }

    return window.dataLayer
  }

  return [ sendDatalayerHeaderEvent ]
}

export default useDataLayerHeader
